import React, { Fragment } from "react";
import { UAnchorLink } from "../../components/UAnchorLink";
import { Box, Flex, Heading, Text, Link, Button } from "rebass";
import Hero from "../../components/Hero";
import ProjectTitleListing from "../../components/ProjectTitleListing";
import ProjectFadeInTitle from "../../components/ProjectFadeInTitle";
import BackToTop from "../../components/BackToTop";
import { StaticImage } from "gatsby-plugin-image";
import BookScene from "../../components/BookScene";
import Card from "../../components/Card";
import ChatBubble from "../../components/ChatBubble";
import UxStrategyDiagram from "../../icons/UxStrategyDiagram";
import UxStrategyDiagramPXP from "../../icons/UxStrategyDiagramPXP";
import UxStrategyDiagramSO from "../../icons/UxStrategyDiagramSO";
import { Modal } from "../../components/Modal";
import { useModal } from "../../components/useModal";
import Carousel from "../../components/Carousel";
import Close from "../../icons/Close";

//Assets
import img1 from "../../img/bose/b-card-definition-and-anatomy.jpg";
import img2 from "../../img/bose/b-card-mobile first.jpg";
import img3 from "../../img/bose/b-card-eyebrow-label.jpg";
import img4 from "../../img/bose/b-card-action-labels.jpg";
import img5 from "../../img/bose/b-card-visual-hierachy.jpg";

import imgUserTesting1 from "../../img/bose/b-user-testing-screen-1.jpg";
import imgUserTesting2 from "../../img/bose/b-user-testing-screen-2.jpg";
import imgUserTesting3 from "../../img/bose/b-user-testing-screen-3.jpg";
import imgUserTesting4 from "../../img/bose/b-user-testing-screen-4.jpg";

//Videos
import PXPVideoIntro from "../../img/bose/PXP-intro.mp4";
import PXPVideoTheme from "../../img/bose/PXPVideoTheme.mp4";
import SOIntro from "../../img/bose/SO-intro.mp4";

//SVGs
import SourceOfTruth from "../../icons/SourceOfTruth";
import CustomerInsights from "../../icons/CustomerInsights";
import EcomBestDesignPractices from "../../icons/EcomBestDesignPractices";
import EcomPlatform from "../../icons/EcomPlatform";

function ProjectBose() {
  {/* const modalPersona = useModal(); */}
  const modalActionItems = useModal();
  const modalMobileFirst = useModal();

  let pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const section1 = pathname + "#section1";
  const section2 = pathname + "#section2";
  const section3 = pathname + "#section3";
  return (
    <section>
      {/* On page navigation */}
      <Flex justifyContent="center">
        <Box
          sx={{
            width: ["100%", "auto", "auto"],
            backgroundColor: "background",
            px: [1],
            borderRadius: "default",
            position: "fixed",
            bottom: [0, 2, 3],
            left: [2, "auto", "auto", "auto", "auto"],
            right: ["auto", "auto", "auto", 2, 2],
            zIndex: "999",
            whiteSpace: "nowrap",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <UAnchorLink
            to={section1}
            sx={{ variant: "buttons.outline", mx: [1] }}
            title="Overview"
          >
            Overview
          </UAnchorLink>
          <UAnchorLink
            to={section2}
            sx={{ variant: "buttons.outline", mx: [1] }}
            title="Design System - Foundations"
          >
            Design System
          </UAnchorLink>
          <UAnchorLink
            to={section3}
            sx={{ variant: "buttons.outline", mx: [1] }}
            title="Immersive Experiences"
          >
            Immersive brand stories
          </UAnchorLink>
        </Box>
      </Flex>
      {/* Hero */}
      <div id="section1">
        <Hero
          title={"Bose — Sound"}
          titleRow={"is power"}
          titleClassName={"uppercase"}
          subtitle={"Setting the foundations for an immersive experience"}
          shortDesc={
            <Fragment>
              LEAP is the codename we've adopted to describe our new platform,
              featuring a revamped design and a modernized tech stack. This
              transition involved migrating from Adobe Experience Manager (AEM)
              to Salesforce. <br />
              As a team, we were trying to create a shared UX mindset within the
              teams we are working with. It was a process that required
              education, patience and a lot of repetition.
            </Fragment>
          }
          year={"2021 — 2024"}
          role={
            <Fragment>
              Design Systems <br /> UX Strategy <br /> Planning <br />
              Design + Execution <br /> Dev Support
            </Fragment>
          }
          roleDesc={
            <Fragment>
              {/* <h2>My contribution</h2>
              <strong>1. Design Systems</strong> — I led and guided the creation
              of foundational elements for design systems, facilitating
              consistent and scalable design solutions. Additionally, I produced
              critical documentation to align design and development teams.
              Ensuring the quality of new implementations, I held demo sessions,
              conducted thorough reviews, and promptly addressed critical issues
              through effective escalation channels.
              <strong>2. Immersive brand stories</strong> — I played a key role
              in guiding and collaborating with the CX optimization team,
              leading the development of a customer-centric information
              architecture for explore and flagship pages. Our strategy focused
              on leveraging immersive experiences driven by a passion for sound,
              aiming to build lasting customer relationships. Educating the user
              on the product’s possibilities and its usage through storytelling.
              Convince the user that the product is the right one for them as
              well as propose similar products that respond to their needs. */}
            </Fragment>
          }
          disciplines={
            <Fragment>
              Marketing &mdash; Content &mdash; Design &mdash; Development
              &mdash; CRO &mdash; SEO
            </Fragment>
          }
          challenge={
            <Fragment>
              Achieve a unified, customer-centric digital ecosystem
              {/* We faced the challenge of bringing the brand to life in digital
              while considering business needs. The approach involved a blend of
              experiences, services and commerce that serves our user’s needs
              and connects with them on an emotional level. Our current platform
              faced several recurring issues that affected the user experience:
              <ul>
                <li>
                  <strong>Inconsistent design</strong> — Appearance and user
                  experience vary widely across pages.
                </li>
                <li>
                  <strong>Perceived limitations</strong> — The platform is seen
                  as a constraint, not an enabler.
                </li>
                <li>
                  <strong>Lack of visibility</strong> — Teams are unaware of
                  what can be achieved within the system.
                </li>
                <li>
                  <strong>Rigid platform</strong> — Making organic and holistic
                  updates is difficult.
                </li>
              </ul>
              <br />
              <Heading as="h3" variant="catHeading">
                Our goals
              </Heading>
              The UX team ambition was to create a connected, branded, ecosystem
              at scale:
              <ul>
                <li>
                  Develop a deep understanding of our users and how they
                  interact with the brand
                </li>
                <li>
                  Solving customer pain points and offer moments of delights
                </li>
                <li>
                  Provide short term value to the business that align with the
                  long term vision
                </li>
              </ul> */}
            </Fragment>
          }
        />
        <BookScene
          coverColor={"#f0c844"}
          textColor={"silver"}
          urlTexture={"/models/textures/bose-book-cover.jpg"}
        />
      </div>
      
      <Flex 
        flexWrap="wrap"
        justifyContent="center"
        variant="container"
      >
        <Box width={[1 / 1, 1/2]} mb={[4,5]}>
          <Card
            eyebrow={"Design System Foundations"}
            page="/work/bose/bose-design-system/"
            info={
              <Fragment>
                <Text as="p">
                  <strong>Tagline</strong>
                </Text>
              </Fragment>
            }
            img={img1}
          />
          </Box>
          <Box width={[1 / 1, 1/2]} mb={[4,5]}>
          <Card
            eyebrow={"Immersive Brand Stories"}
            page="/work/bose/bose-immersive-brand-stories/"
            info={
              <Fragment>
                <Text as="p">
                  <strong>Tagline</strong>
                </Text>
              </Fragment>
            }
            img={img1}
          />
          </Box>
      </Flex>
      
      <div id="section2">
        {/* #1 FOUNDATIONS */}
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box width={[1 / 1, 8 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Solid"}
              word1ClassName={"outline"}
              word2={"Foundations"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 4 / 12]} pl={[0, 5]}>
            <Heading as="h3" variant="catHeading">
              Design System
            </Heading>
            <Box maxWidth="700px" mx="auto" color="bigTitle">
              <SourceOfTruth />
            </Box>
          </Box>

          <Box variant="containerTxt">
            {/* User story */}
            <Heading as="h3" variant="catHeading">
              User story
            </Heading>
            <Text as="p" pb={[3, 4]} mb={[4, 5, 6]} variant="paragraphXL">
              <strong>As a</strong> brand <br />
              <strong>I want to</strong> establish a solid foundation within our
              design system
              <br />
              <strong>So that</strong> we can maintain a consistent brand
              identity across all digital platforms and enhance customer
              recognition and loyalty.
            </Text>

            <Heading as="h3" variant="bigTitleXS">
              Setting ourselves up for success
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              Our objective was to encourage both our internal teams, external
              vendors and agencies, to adopt and work within the boundaries of a
              design system. Recognizing that resistance to adopting new systems
              is common, we concentrated on establishing solid foundations that
              could adapt to our rapidly evolving business and increasingly
              diverse user base.
            </Text>

            <Box mb={[4, 5]}>
              <Flex flexWrap={["wrap", "nowrap"]} justifyContent="center">
                <Box
                  width={["auto", 1 / 1, 1 / 2]}
                  p={[3, 4]}
                  variant="chatBubble"
                  mb={[4, 3]}
                  mr={[0, 2]}
                >
                  <Text as="p">We need a cross-functional team</Text>
                </Box>

                <Box
                  width={["auto", 1 / 1, 1 / 2]}
                  p={[3, 4]}
                  variant="chatBubble"
                  mb={[4, 3]}
                  mx={[0, 2]}
                >
                  <Text as="p">We need established rituals</Text>
                </Box>

                <Box
                  width={["auto", 1 / 1, 1 / 2]}
                  p={[3, 4]}
                  variant="chatBubble"
                  mb={[4, 3]}
                  ml={[0, 2]}
                >
                  <Text as="p">We need accesible documentation</Text>
                </Box>
              </Flex>
            </Box>
            <Text as="p" mb={[3, 4, 5]}>
              <strong>
                The design system had to empower us to gain a competitive
                advantage through user-centric design, enabling the creation of
                a transformative digital experience at scale.
              </strong>
            </Text>
            <Heading as="h3" variant="catHeading">
              Our goals
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              A single source of truth
            </Heading>
            <Text as="p" mb={[5, 6]}>
              Our high level goals were to centralize our understanding of the
              design system to make it easier to understand and use:
              <ul>
                <li>Make it fast and easy to use for everyone, everywhere.</li>
                <li>
                  Create a platform for innovation and deeper engagement.{" "}
                </li>
                <li>More consistency and more clarity</li>
              </ul>
            </Text>

            <Heading as="h2" variant="bigTitleXS">
              Identifying challenges
            </Heading>
            <Text as="p" mb={[5, 6]}>
              Our Figma library, rich with comments, served as a decision log to
              pinpoint recurring issues encountered by teams. As daily demands
              surged and requests multiplied, we faced the challenge of
              maintaining consistency across two distinct platforms.
            </Text>
            <Heading as="h3" variant="bigTitleXS">
              #1 Inconsistency
            </Heading>
            <Text as="p" mb={[3, 4]}>
              An initial audit of the design system components revealed
              misalignments among team members regarding changes and new
              proposals, highlighting the need to refine our processes and
              establish clear governance.
            </Text>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-ds-component-issues.jpg"
                alt="Design System - Insonsistent vomponent naming convention"
              />
            </Box>
            <Text as="span" variant="caption" mb={[4, 5, 6]}>
              Inconsistent naming conventions across the same component, with
              configurable properties not clearly visible.
            </Text>

            <Heading as="h3" variant="bigTitleXS">
              #2 Lack of documentation
            </Heading>
            <Text as="p" mb={[5, 6]}>
              While addressing development changes and resolving issues, we
              discovered a significant disconnect between design, front-end, and
              authoring teams. Each team referred to different files in search
              of the source of truth, leading to confusion and inconsistency due
              to some unmaintained files and components.
            </Text>

            <Heading as="h3" variant="bigTitleXS">
              #3 No governance
            </Heading>
            <Text as="p">
              When there is no governance over a design system, several
              challenges can arise, often leading to inefficiency and
              inconsistency across an organization's projects.{" "}
              <ul>
                <li>Inconsistency</li>
                <li>Redundancy</li>
                <li>Quality issues</li>
                <li>Scalability problems</li>
                <li>Adoption Barries</li>
                <li>Decreased collaboration</li>
              </ul>
            </Text>
          </Box>
        </Flex>
        {/* Design System is a product */}
        <Flex
          flexWrap="wrap"
          py={[3, 4]}
          variant="container"
          justifyContent="center"
          mb={[4, 5]}
        >
          <Box width={[1 / 1, 8 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Design"}
              word2={"System"}
              word2ClassName={"outline"}
              word3={"is"}
              word3ClassName={"outline"}
              word4={"a Product"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 4 / 12]} pl={[0, 5]} color="bigTitle">
            <Heading as="h3" variant="catHeading">
              Design System
            </Heading>
            <CustomerInsights />
          </Box>

          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Learnings
            </Heading>
            <Text as="p" pb={[3, 4]} mb={[5, 6]} variant="paragraphXL">
              A design system is a transformative{" "}
              <strong>way of working</strong>, a scalable resource, and the
              ultimate <strong>source of truth</strong>. Adopting such systems
              represents a significant cultural shift, and any resistance to
              this change must be thoughtfully addressed and managed.
            </Text>
            <Heading as="h2" variant="bigTitleXS" mb={[3]}>
              A way of working
            </Heading>
            <Box width={[1 / 1]} mb={[4]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-design-system-Figma-setup.jpg"
                alt="Design System - Figma Setup"
              />
            </Box>

            <Text as="p" pb={[3, 4]}>
              I’ve established a structured approach by organizing Figma files
              according to team roles:
              <ul>
                <li>
                  <strong>Library (The Source of Truth)</strong> &mdash; houses
                  all in use design elements and components.
                </li>
                <li>
                  <strong>Template (Library in Use)</strong> &mdash; utilizes
                  the library within specific guidelines.
                </li>
                <li>
                  <strong>Working Files (Design Freedom)</strong> &mdash;
                  flexibility in using and adapting the design system.
                </li>
              </ul>
              This clear structure enabled us to introdude a process using Figma
              branches for implementing changes and introducing new components.
              This approach not only maintained oversight but also provided a
              decision log. This log helps inform stakeholders about project
              timelines, capacity, and associated costs.
            </Text>

            <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
              Summary of achievements
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              <ul>
                <li>
                  <strong>Guidelines and recommendations</strong> &mdash; I led
                  the creation of guidelines and recommendations to support our
                  creative teams.
                </li>
                <li>
                  <strong>Design system and CMS integration</strong> &mdash;
                  guided the team to bridge the gap between the CMS and the
                  design system library to facilitate collaboration between
                  authoring and design.
                </li>
                <li>
                  <strong>Naming conventions</strong> &mdash; Standardized and
                  refined naming conventions, resolving inconsistencies to boost
                  the reusability of our assets.
                </li>
                <li>
                  <strong>Component Optimization</strong> &mdash; I’ve led the
                  efforts to provide structure and better performance of the
                  components in Figma and in development in order to ensure the
                  platform is scalable.
                </li>
                <li>
                  <strong>Quality assurance</strong> &mdash; Held demo sessions,
                  conducted in-depth reviews, and addressed critical issues
                  through effective escalation channels.
                </li>
              </ul>
            </Text>

            {/* Card System */}
            <Heading as="h3" variant="catHeading">
              Highlight
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Card System
            </Heading>
            <Text as="p" mb={[3, 4]}>
              Before &#8594; Desktop first
            </Text>

            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-card-system-before.jpg"
                alt="Card System - Before"
              />
            </Box>
            <Text as="p" variant="caption" mb={[4]}>
              Issues with visual hierarchy made it difficult for users to
              quickly skim through information. Furthermore, the entire card not
              being clickable contributed to a high bounce rate and low
              click-through rates.
            </Text>

            <Text as="p" mb={[3, 4]}>
              After &#8594; Mobile first
            </Text>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-card-system-after.jpg"
                alt="Card System - After"
              />
            </Box>
            <Text as="p" variant="caption" mb={[4, 5, 6]}>
              We improved the customer experience by implementing internal
              guidelines and recommendations, ensuring alignment between Figma
              and our CMS with consistent, configurable options.
            </Text>

            {/* Mobile first */}
            <Heading as="h2" variant="bigTitleXS">
              A mobile-first experience should never feel like a compromise
            </Heading>
            <Text as="p" mb={[2, 3, 4]}>
              To accommodate the fact that approximately{" "}
              <strong>80% of our web traffic comes from mobile devices</strong>,
              it became imperative to adopt a mobile-first approach that
              transcends the notion of compromise. We aimed to move beyond a
              "good enough" mentality by integrating visual hierarchy and robust
              design system rules. This new strategy allows us to craft
              instantly impactful and deserving designs optimized for the mobile
              experience. <br /><br />
              <ul>
                <li>Simplify by reducing moving parts.</li>
                <li>Prioritize only important information and actions.</li>
                <li>Responsive design is a must.</li>
              </ul>  
            </Text>
            

           {/*ModalMobileFirst */}
            <Button
              mb={[5, 6]}
              sx={{ variant: "buttons.primary", fontSize: [2, 2] }}
              onClick={modalMobileFirst.toggle}
            >
              Learn more <Close />
            </Button>

            <Modal
              isShown={modalMobileFirst.isShown}
              hide={modalMobileFirst.toggle}
              headerText="Mobile first"
              modalContent={
                <Fragment>
                  <Text as="p" pb={[3, 4]}>
                    Creating a mobile-first experience involves simplification
                    and strategic prioritization to enhance usability on smaller
                    devices.
                  </Text>
                  <Heading as="h3" variant="catHeading">
                    Golden Rules
                  </Heading>
                  <Text as="p">
                    <ul>
                      <li>
                        <strong>Simplify:</strong> <br /> Reduce the number of
                        moving parts to minimize distraction and enhance user
                        focus.
                      </li>
                      <li>
                        <strong>
                          Prioritize navigation, information and actions:
                        </strong>
                        <br /> Clearly define what's important, making screens
                        intuitive and straightforward.
                      </li>
                      <li>
                        <strong>Iterate on design fidelity:</strong>
                        <br /> Focus on refining design details over adding new
                        functionalities.
                      </li>
                      <li>
                        <strong>Use Patterns:</strong>
                        <br /> Start with established design patterns that are
                        known to work, and continuously test and refine them.
                      </li>
                      <li>
                        <strong>Highlight essential information:</strong>
                        <br /> Use progressive disclosure to present
                        information, revealing more details as needed.
                      </li>
                      <li>
                        <strong>Ensure responsiveness:</strong>
                        <br /> Design components that scale smoothly across
                        different screen sizes.
                      </li>
                      <li>
                        <strong>Adopt fluid typography:</strong>
                        <br /> Utilize flexible typography that adjusts well to
                        varying screen sizes.
                      </li>
                      <li>
                        <strong>Use relative units:</strong>
                        <br /> Employ CSS units like <code>rems</code> and
                        functions like <code>clamp()</code> for flexible sizing.
                      </li>
                      <li>
                        <strong>Maintain aspect ratios:</strong>
                        <br /> Ensure that images and videos maintain their
                        aspect ratios to avoid distortion.
                      </li>
                      <li>
                        <strong>Avoid full-screen carousels:</strong> <br />
                        They can be cumbersome on mobile; opt for more
                        user-friendly alternatives.
                      </li>
                      <li>
                        <strong>Optimize content layout:</strong>
                        <br /> Make entries recognizable and easy to skim
                        through.
                      </li>
                      <li>
                        <strong>Enhance orientation and navigation:</strong>{" "}
                        <br />
                        Always indicate to users where they are within the app
                        or website and provide an easy way to return to previous
                        screens.
                      </li>
                      <li>
                        <strong>Keep it simple:</strong> Strive for minimalism
                        and clarity in every element of your design.
                      </li>
                    </ul>
                  </Text>
                </Fragment>
              }
            />
            
            {/* Card System - Reduce complexity */}
            <Heading as="h3" variant="catHeading">
              Highlight
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Reduce complexity
            </Heading>
            <Text as="p" mb={[3, 4]}>
              Before &#8594; 24 variants (for one component) 
            </Text>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-card-system-reduce-complexity-before.jpg"
                alt="Card System - Reduce complexity - Before"
              />
            </Box>
            <Text as="p" variant="caption" mb={[4]}>
              The example above illustrates the complexity of one component &mdash; eCommerce Card, which comprised 24 variants. Our card system had 11 cards in total, each with at least four variants. Adding the numbers up we ended up with ~300 variants. Maintenance and updates often required weeks to complete, if they were even done in the first place.
            </Text>
            
            <Text as="p" mb={[3, 4]}>
              After &#8594; 4 variants 
            </Text>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-card-system-reduce-complexity-after.jpg"
                alt="Card System - Reduce complexity - After"
              />
            </Box>
            <Text as="p" variant="caption" mb={[4, 5, 6]}>
              We enhanced the configurability of our card variants, making them agnostic to their usage contexts. Now, these cards can be utilized across multiple areas of the website by simply toggling options on and off, significantly increasing their versatility and ease of use. 
            </Text>
            
            {/* Connecting the dots */}
            <Heading as="h2" variant="bigTitleXS">
              Connecting the dots
            </Heading>
            <Text as="p" mb={[4]}>
              Our cards, spread across diverse templates, offer customers
              seamless access to information. Yet, working on different projects
              can cause a disconnect between these components. To counter this,
              we stressed that each card is part of a bigger picture, focusing
              on consistency and familiarity to maintain a coherent user
              experience. <br /> <br />
              Additionally, we equipped visual designers with multiple
              stylization options for cards, allowing the use of any feature
              artwork while also supporting content with landscape imagery or
              without any artwork, enhancing versatility across content types.
            </Text>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-card-system-mobile-first-flow.jpg"
                alt="Card System - Mobile first - flow"
              />
            </Box>
            <Text as="span" variant="caption" mb={[4, 5, 6]}>
              Ensure consistent representation across the platform to help users
              understand their navigation path without confusion.
            </Text>
            <Heading as="h2" variant="bigTitleXS">
              Guidelines &amp; recommedations
            </Heading>
            <Text as="p" mb={[2, 3, 4]}>
              We've developed documentation to help cross-functional teams fully
              understand the system, enabling them to produce their best work
              within established limitations.
            </Text>
            <Heading as="h3" variant="catHeading">
              Note
            </Heading>
            <Text as="p">
              It goes beyond just colors, typography, and UI buttons. The focus
              is on the relationships between Figma, CMS, and the e-commerce
              platform, viewing our design efforts holistically to understand
              how changes impact the entire system. This approach ensures that
              every modification enhances system coherence and functionality.
            </Text>
          </Box>
        </Flex>

        <Carousel img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} />
      </div>
      <div id="section3">
        {/* Immersive brand stories*/}
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="immersive"
        >
          <Box width={[1 / 1]}>
            <ProjectFadeInTitle
              word1={"Immersive"}
              word2={"Brand"}
              word2ClassName={"outline"}
              word3={"Stories"}
              className={"uppercase"}
            />
          </Box>
          <Box variant="containerTxt">
            {/* User story */}
            <Heading as="h3" variant="catHeading">
              User story
            </Heading>
            <Text as="p" mb={[4, 5]} variant="paragraphXL">
              <strong>As a</strong> music lover <br />
              <strong>I want to</strong> use interactive and educational tools
              to explore and understand sound
              <br />
              <strong>So that</strong> I can find products that meet my specific
              sound needs and enhance my listening experience.
            </Text>

            <Heading as="h3" variant="catHeading">
              UX Strategy
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Desire must precede purchase
            </Heading>
            <Text as="p" pb={[3, 4]} mb={[4, 5]}>
              Our UX Strategy approach aligned with established business goals,
              focusing on illustrating the power of sound by creating immersive
              experiences that encourage exploration, not just shopping,
              prioritizing the consumer's overall sound experience over mere
              transactions.
              <br />
              Under this umbrella I led the UX efforts to improve, guide and
              optimize the 'Explore section on Bose.xx. Under the explore
              section we have entries to what internally we called ‘Sound
              Occasions’. Following the positive feedback we've approached the
              same priciples to improve the product experience pages(PXPs).
            </Text>

            {/* Navigational path */}
            <Text as="p" variant="paragraphXL" mb={[1, 2, 3]}>
              <strong>Simplified navigational path</strong>
            </Text>

            <Flex flexWrap="wrap">
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  Find
                </Heading>
                <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                  <strong>SHOP</strong> <br />
                  Direct path to purchase via intuitive groupings.
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]} mb={[2, 3, 4]}>
                <Heading as="h3" variant="catHeading">
                  Explore
                </Heading>
                <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                  <strong>EXPLORE</strong> <br />
                  Discover based on listening moments.
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  HELP
                </Heading>
                <Text as="p">
                  <strong>SUPPORT</strong> <br />
                  Best-in-class customer support.
                </Text>
              </Box>
            </Flex>

            <Box width={[1 / 1]} mt={[4, 0, 0]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-new-site-structure.jpg"
                alt="New site structure"
              />
            </Box>
            <Text as="span" variant="caption">
              The new site structure integrates analytics, user insights,
              content, and strategy to guide customers to products they love,
              outperforming the previous taxonomy according to user testing.
            </Text>
          </Box>

          <Box width={[1 / 1]}>
            <Flex
              flexWrap="wrap"
              variant="container"
              justifyContent="center"
              mb={[1, 1, 1]}
            >
              <Box width={[1 / 1, 1 / 2]} variant="containerCard">
                <Card
                  sx={{ backgroundColor: "bigTitle", color: "background" }}
                  title={"Sound Occasions"}
                  subheading={"Cross-category groupings"}
                  desc={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        Explore
                      </Heading>
                      <Text as="p">Bridge the digital-physical by allowing customers to 'try' and 'feel' products and discover the ideal product for their sound needs.</Text>
                    </Fragment>
                  }
                  info={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        user mindset
                      </Heading>
                      <Text as="p">
                        <strong>
                          "I don't know which product will meet my need."
                        </strong>
                      </Text>
                      {/* <ul>
                    <li>product discovery</li>
                    <li>product awareness & consideration</li>
                    <li><strong>conversion and purchase</strong></li>
                  </ul> */}
                    </Fragment>
                  }
                />
              </Box>
              <Box width={[1 / 1, 1 / 2]} variant="containerCard">
                <Card
                  title={"PLP"}
                  subheading={"Category overview"}
                  desc={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        Find
                      </Heading>
                      <Text as="p">Provides a fast and guided way to identify the ideal product from a diverse range, highlighting their distinct features.</Text>
                    </Fragment>
                  }
                  info={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        user mindset
                      </Heading>
                      <Text as="p">
                        <strong>"I'm looking for some headphones."<br /><br /></strong>
                      </Text>
                      {/* <ul>
                    <li>product discovery</li>
                    <li>product awareness & consideration</li>
                    <li><strong>conversion and purchase</strong></li>
                  </ul> */}
                    </Fragment>
                  }
                />
              </Box>
            </Flex>
          </Box>

          <Box width={[1 / 1]}>
            <Flex
              flexWrap="wrap"
              py={[3, 4]}
              variant="container"
              justifyContent="center"
              mb={[4, 5, 6]}
            >
              <Box width={[1 / 1, 1 / 2]} variant="containerCard">
                <Card
                  sx={{ backgroundColor: "bigTitle", color: "background" }}
                  title={"PXPs"}
                  subheading={"Flagship product"}
                  desc={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        Learn
                      </Heading>
                      <Text as="p">Center the site around a core group of products and provide content that allows customers to immerse themselves, fostering a sense of ownership.</Text>
                    </Fragment>
                  }
                  info={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        user mindset
                      </Heading>
                      <Text as="p">
                        <strong>
                          "I'm looking for the best noise cancelling
                          headphones."
                        </strong>
                      </Text>
                      {/* <ul>
                    <li>product discovery</li>
                    <li>product awareness & consideration</li>
                    <li><strong>brand awareness</strong></li>
                  </ul> */}
                    </Fragment>
                  }
                />
              </Box>
              <Box width={[1 / 1, 1 / 2]} variant="containerCard">
                <Card
                  title={"PDP"}
                  subheading={"Product information"}
                  desc={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        Purchase
                      </Heading>
                      <Text as="p">Enable quick comprehension to help customers confidently confirm their choices and make purchases.</Text>
                    </Fragment>
                  }
                  info={
                    <Fragment>
                      <Heading as="h3" variant="catHeading">
                        user mindset
                      </Heading>
                      <Text as="p">
                        <strong>
                          "I need headphones for work, with specific features."
                        </strong>
                      </Text>
                      {/* <ul>
                    <li>product discovery</li>
                    <li>product awareness & consideration</li>
                    <li><strong>brand awareness</strong></li>
                  </ul> */}
                    </Fragment>
                  }
                />
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          mb={[2, 2, 2, 2, 2]}
        >
          <Box variant="containerTxt">
            <Heading as="h2" variant="bigTitleXS">
              Customer insights
            </Heading>
            <Heading as="h3" variant="catHeading">
              Persona
            </Heading>
            <Text as="p" variant="paragraphXL" mb={[1, 2, 3]}>
              <strong>We serve the music lover</strong>
            </Text>
            <Text as="p" mb={[3, 4]}>
              Our design decisions were guided by a persona deeply rooted in the
              love for music and technological enhancements. This persona,
              characterized by a strong desire to "feel the music," values
              quality and immersive sound experiences that allow them to listen
              critically, relax, and set the mood according to their
              preferences.
            </Text>

            {/* modalPersona */}
            {/* <Button
              mb={[5,6]}
              sx={{ variant: "buttons.primary", fontSize: [2, 2] }}
              onClick={modalPersona.toggle}
            >
              Learn more <Close />
            </Button>

            <Modal
              isShown={modalPersona.isShown}
              hide={modalPersona.toggle}
              headerText="The Music Lover"
              modalContent={
                <Fragment>
                  <Text as="p" pb={[3, 4]} variant="paragraphXL">
                    Music is more than just background noise; it's an expression
                    of their personality, intertwined with their daily
                    activities and emotional states.
                  </Text>
                  <Heading as="h3" variant="catHeading">
                    About
                  </Heading>
                  <Text as="p" mb={[3, 4, 5]}>
                    Passionate about not just music but also movies and the
                    latest technology, this persona seeks products that enhance
                    their engagement with entertainment. They prioritize
                    high-quality sound and are willing to invest more in
                    products that promise an uninterrupted, enhanced listening
                    experience. This is someone who actively seeks out new
                    features that can elevate their sensory experiences, whether
                    that’s through crystal-clear sound quality or innovative
                    user interfaces. <br />
                  </Text>
                  <Heading as="h3" variant="catHeading">
                    Behavior
                  </Heading>
                  <Text as="p">
                    Their engagement with music extends beyond personal
                    listening; it's a social experience too. They frequent
                    festivals and gigs, always on the lookout for the next great
                    live performance. This persona is not just a consumer but an
                    enthusiast and advocate for superior sound, willing to pay a
                    premium for devices that can keep up with their high
                    standards and vibrant lifestyle. This understanding helps us
                    tailor products and features that meet their needs, ensuring
                    we create solutions that resonate well with their lifestyle
                    and aspirations.
                  </Text>
                </Fragment>
              }
            /> */}

            <Heading as="h3" variant="catHeading">
              Pain points
            </Heading>
            <Text as="p" variant="paragraphXL" mb={[1, 2, 3]}>
              <strong>A look at user pain points</strong>
            </Text>
            <Text as="p">
              Addressing the pain points of our target persona revealed several
              challenges that affect their experience and satisfaction.
            </Text>
            <ul>
              <li>too many options</li>
              <li>too engineered and serious</li>
              <li>not understanding how products fit their life</li>
              <li>difficult to evaluate many options</li>
              {/* <li>passionate about music - cannot feel sound (mobile video)</li> */}
            </ul>

            <Text as="p" mb={[5, 6]}>
              There is a gap in understanding how different products can
              seamlessly integrate into their lifestyle, particularly in terms
              of usability and practicality. This disconnect often leaves them
              unable to visualize the benefits of a product in their daily music
              listening and entertainment routines.
            </Text>

            <Heading as="h2" variant="bigTitleXS">
              User testing learnings
            </Heading>
            <Text as="p">
              We aimed to assess the effectiveness of the product experience
              page's storytelling in educating and encouraging potential buyers.
              Additionally, we sought to understand customer reactions to copy,
              imagery, and page rhythm to determine if anything is missing from
              the experience.
            </Text>
            <Text as="p" variant="caption">
              The interview was conducted in partnership with an external
              agency, guided by the UX strategy we developed.
            </Text>
          </Box>
        </Flex>

        <Carousel
          component1={
            <ChatBubble
              learning={
                "Users express preferences for specific product colors and visuals."
              }
              quote={
                <Fragment>
                  <Text as="p">
                    "It's nice to know there are colors, but I will say that
                    blue color is not as appealing as this neutral color."
                  </Text>
                </Fragment>
              }
              author={"UserZoom participant"}
              img={imgUserTesting4}
            />
          }
          component2={
            <ChatBubble
              learning={"The copy creates confusion rather than clarity."}
              quote={
                <Fragment>
                  <Text as="p">
                    "This is about noise cancellation but this is kind of a
                    confusing message."
                  </Text>
                </Fragment>
              }
              author={"UserZoom participant"}
              img={imgUserTesting3}
            />
          }
          component3={
            <ChatBubble
              learning={"Need to skim trough information."}
              quote={
                <Fragment>
                  <Text as="p">
                    "This information seems important but it's hard to skim
                    this."
                  </Text>
                </Fragment>
              }
              author={"UserZoom participant"}
              img={imgUserTesting1}
            />
          }
          component4={
            <ChatBubble
              learning={"Feature descriptions are prone to misinterpretation."}
              quote={
                <Fragment>
                  <Text as="p">
                    "With 11 listening modes, can I create various profiles for
                    the noise cancellation... one for work, one for home,
                    etc...?"
                  </Text>
                </Fragment>
              }
              author={"UserZoom participant"}
              img={imgUserTesting2}
            />
          }
        />

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          mt={[3, 4, 5, 6, 7]}
        >
          <Box width={[1 / 1, 7 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Sound"}
              word2ClassName={"outline"}
              word3={"Occasion"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 5 / 12]} pl={[0, 5]}>
            <Heading as="h3" variant="catHeading">
              Themed shopping
            </Heading>
            <EcomPlatform />
            <Text as="span" variant="caption">
              Uncover customer needs and priorities to identify the Bose product
              for the sound occasion (activity) that matters to them.
            </Text>
          </Box>
          <Box width={[1 / 1]} mt={[3, 4, 5]} variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Explore
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              What are Sound Occasions?
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              Sound occasion pages respond to the "listening moments" strategy,
              tapping on our{" "}
              <strong>
                learnings about how customers use their products in specific
                contexts.
              </strong>
            </Text>
            <Box width={[1 / 1]} mb={[4, 5, 6]} color="bigTitle">
              <UxStrategyDiagram />
            </Box>
            <Heading as="h2" variant="bigTitleXS">
              Shop by Occasion strategy
            </Heading>

            <Text as="p" mb={[4]}>
              These pages allow us to showcase multiple product categories
              around one use case, explain how uniquely Bose technology delivers
              better sound, and tell a more engaging story that goes beyond just
              product.
              <ul>
                <li>
                  <strong>Guide users</strong> that don’t know much or haven’t
                  yet made up their mind about a specific range of products
                </li>
                <li>
                  <strong>Showcase currated products</strong> based on specific
                  use cases around occasions locations and locations
                </li>
              </ul>
            </Text>

            <Box width={[1 / 1]} mb={[4, 5, 6]}>
              <Flex
                flexWrap="wrap"
                justifyContent="center"
                alignItems="flex-end"
              >
                <Box width={[1 / 1, 1 / 2]} mb={[4, 0, 0]}>
                  <Box maxWidth="390px" alignItems="self-end">
                    <UxStrategyDiagramSO />
                  </Box>
                </Box>
                <Box width={[1 / 1, 1 / 2]} pr={[0, 3, 4]}>
                  <Text as="p" variant="paragraphXL">
                    <strong>Simply and go</strong>
                  </Text>
                  <Text as="p">
                    I developed an initial concept to guide our information
                    architecture, serving as our north star. With limited time
                    for research, we leveraged the existing knowledge and relied
                    on our expertise and best judgment.
                  </Text>
                </Box>
              </Flex>
            </Box>

            <Heading as="h2" variant="bigTitleXS">
              Goals
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              <ul>
                <li>
                  Leverage the explore section to drive more organic traffic
                </li>
                <li>Surface relevant content to website visitors</li>
                <li>
                  Improve page exploration, discoverability and better connect
                  to other areas of the website
                </li>
              </ul>
            </Text>

            <Heading as="h2" variant="bigTitleXS">
              Action items
            </Heading>
            <Text as="p" mb={[3]}>
              In order to achieve our goals we’ve covered the following action
              items
              <ul>
                <li>
                  <strong>Defined purpose</strong> of SO/PXP Pages to highlight
                  products, integrate categories and drive emotional engagement.
                </li>
                <li>
                  <strong>Audit & optimization of motion</strong> to assess
                  animation and transitions, establish motion principles and
                  create modular reusable components.
                </li>
                <li>
                  <strong>Guide development</strong> by setting rules and motion
                  guidelines, balanced page elements and improve UX
                  contextually.
                </li>
              </ul>
            </Text>
            {/* ModalActionItems */}
            <Button
              mb={[5, 6]}
              sx={{ variant: "buttons.primary", fontSize: [2, 2] }}
              onClick={modalActionItems.toggle}
            >
              Learn more <Close />
            </Button>

            <Modal
              isShown={modalActionItems.isShown}
              hide={modalActionItems.toggle}
              headerText="Action items"
              modalContent={
                <Fragment>
                  {/* Purpose */}
                  <Heading as="h3" variant="catHeading">
                    Step #1
                  </Heading>
                  <Heading
                    as="h2"
                    variant="bigTitleXS"
                    sx={{ color: "inherit" }}
                  >
                    Purpose
                  </Heading>
                  <Text as="p" mb={[3,4]}>
                    <strong>Showcase products:</strong> Highlight our products
                    using the "listening moments" strategy to cater to specific
                    user needs.
                    <br />
                    <strong>Cross-category integration:</strong> Demonstrate the
                    synergy between different products in various use cases to
                    illustrate 'Better Together' scenarios.
                    <br />
                    <strong>Emotional engagement:</strong> Craft narratives that
                    create emotional impact and seamless user flow.
                  </Text>
                  <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                    <StaticImage
                      src="../../img/bose/b-action-items-purpose.jpg"
                      alt="Purpose example"
                    />
                  </Box>
                  {/* Audit */}
                  <Heading as="h3" variant="catHeading">
                    Step #2
                  </Heading>
                  <Heading
                    as="h2"
                    variant="bigTitleXS"
                    sx={{ color: "inherit" }}
                  >
                    Audit & optimization of motion
                  </Heading>
                  <Text as="p" mb={[3,4]}>
                    <strong>Motion audit:</strong> Review and assess the purpose
                    of animations and transitions. Identify where motion is
                    lacking and where it's excessive. Evaluate timing curves,
                    choreography patterns, and effect patterns such as scale,
                    opacity, and elevation.
                    <br />
                    <strong>Define motion principles:</strong> Establish
                    guidelines for motion design including easing, duration, and
                    effects. Distinguish between micro and macro animations and
                    their appropriate contexts.
                    <br />
                    <strong>Develop motion building blocks:</strong> Create
                    modular, reusable components focused on the interaction of
                    object, time, and effect. These should include ease,
                    duration, object effects (start/end states), and
                    choreography.
                  </Text>
                  <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                    <StaticImage
                      src="../../img/bose/b-action-items-audit.jpg"
                      alt="Audit example"
                    />
                  </Box>
                  {/* Guideline development */}
                  <Heading as="h3" variant="catHeading">
                    Step #3
                  </Heading>
                  <Heading
                    as="h2"
                    variant="bigTitleXS"
                    sx={{ color: "inherit" }}
                  >
                    Guide development
                  </Heading>
                  <Text as="p" mb={[3,4]}>
                    <strong>Create motion guidelines:</strong> Formulate
                    principles that guide the usage of motion in UX, addressing
                    when and how animations should be employed.
                    <br />
                    <strong>Page rhythm:</strong> Analyze the ratio of animated
                    to static elements to maintain balance and prevent
                    overstimulation. <br />
                    <strong>Contextual UX enhancements:</strong> Consider
                    principles that enhance user experience contextually:
                    <ul>
                      <li>
                        Strengthen affordances and action feedback where
                        necessary.
                      </li>
                      <li>Address user disorientation in navigation.</li>
                      <li>
                        Pinpoint and rectify fall-offs during onboarding
                        processes.
                      </li>
                    </ul>
                  </Text>
                  <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                    <StaticImage
                      src="../../img/bose/b-action-items-guide-development.jpg"
                      alt="Guide development example"
                    />
                  </Box>
                </Fragment>
              }
            />

            {/* Solution & recommendations */}
            <Heading as="h2" variant="bigTitleXS">
              Proposed solutions & recommendations for 'explore' pages
            </Heading>
            <Text as="p" mb={[3, 4]}>
              Our solution centered on developing a story-driven, actionable,
              user-centric page that leverages technology enabled by real-life
              situations to guide users to products.
              <br />
              <strong>
                {" "}
                This approach prioritizes user behavior as the driver of
                technology, with products playing a supportive role.
              </strong>
            </Text>
            <Heading as="h2" variant="catHeading">
              Key ingredients
            </Heading>
            <Text as="p" mb={[3, 4]}>
              <ul>
                <li>Focus around one specific occasion/activity.</li>
                <li>Set context by provide familiar locations.</li>
                <li>Use technology benefits to engage users.</li>
                <li>
                  Showcases cross-category groupings based on shared technology.{" "}
                </li>
                <li>Drive for familiarity and consistency.</li>
              </ul>
            </Text>
            <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/so-ingredients.jpg"
                alt="Key ingredients"
              />
            </Box>
            {/* IA */}
            <Heading as="h3" variant="catHeading">
              Highlight
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Information Architecture
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              While staying true to our overarching goal, we proposed the
              adoption of a formula that not only enhances user exploration but
              also simplifies maintenance for our internal teams.{" "}
            </Text>
            <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-ia-so.jpg"
                alt="Information Architecture - SO"
              />
            </Box>
            {/* Progressive disclosure */}
            <Heading as="h2" variant="bigTitleXS">
              Progressive disclosure
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              Present information gradually, only as it becomes relevant or
              necessary for the user.
              <ul>
                <li>reduce cognitive load</li>
                <li>decluttered UI</li>
                <li>user has control</li>
              </ul>
            </Text>
            <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-progressive-disclosure-so.jpg"
                alt="Progressive disclosure"
              />
            </Box>
            {/* On page navigation */}
            <Heading as="h2" variant="bigTitleXS">
              Clear entry points
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              This approach not only caters to diverse customer interests but
              also aligns with the trend of providing curated and thematic
              product collections to enhance the overall online shopping
              journey.
              <ul>
                <li>familiar groupings that resonated to users</li>
                <li>consistent focus on ocassion</li>
              </ul>
            </Text>
            <Box width={[1 / 1]} mb={[4, 5, 6]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-entry-points-so.jpg"
                alt="Progressive disclosure"
              />
            </Box>

            {/* Proof of concept  */}
            <Box width={[1 / 1]}>
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Proof of concept
              </Heading>
              <Flex flexWrap="wrap" justifyContent="center">
                <Box width={[1 / 1, 1 / 2]} pr={[0, 3, 4]}>
                  <Text as="p" mb={[3]}>
                    Leveraging our information architecture and adhering to our
                    motion principles and guidelines, we aimed to rapidly
                    develop a testing prototype that closely resembles the final
                    product. This self-initiated effort proved successful during
                    discussions with stakeholders, even if it was a work in
                    progress when demoing it.
                  </Text>
                  <Link
                    variant="aLink"
                    href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
                    target="_blank"
                    rel="noopener norefferer"
                    mb={"0px"}
                  >
                    View prototype *
                  </Link>
                  <Text as="span" variant="caption">
                    <strong>*NOTE</strong> Only chosen ones have access so be
                    ready for a password.
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 2]}>
                  <Box maxWidth="390px" alignItems="self-end">
                    <Box variant="wrapperVideoPortrait">
                      <video autoPlay muted loop playsInline>
                        <source src={SOIntro} type="video/mp4" />
                      </video>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>

        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1, 7 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Product"}
              word3={"experience"}
              className={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 5 / 12]} pl={[0, 5]}>
            <Heading as="h3" variant="catHeading">
              Discover
            </Heading>
            <EcomBestDesignPractices />
            <Text as="span" variant="caption">
              Get customers closer to sound and educate by letting them feel,
              try and immerse themselves in the products, while building mental
              ownership.
            </Text>
          </Box>
          <Box variant="containerTxt">
            {/* PXP */}
            <Heading as="h3" variant="catHeading">
              Learn
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              What are PXPs?
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              The PXPs (Product Experience Pages) were developed to address a
              recognized gap in our website's user experience.
            </Text>
            <Box width={[1 / 1]} mb={[5, 6]}>
              <UxStrategyDiagramPXP />
            </Box>
            <Heading as="h2" variant="bigTitleXS">
              PXP strategy
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              <strong>Why PXPs?</strong>
              <br />
              We required a solution to establish hierarchy within our product
              lineup and effectively convey the story of our flagship products
              in a more immersive manner. This solution needed to evoke an
              emotional response and facilitate a "show and tell" experience,
              enabling users to explore, interact with, and develop an affinity
              for the product before transitioning into the purchase phase.
            </Text>

            <Box width={[1 / 1]} mb={[4, 5, 6]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-flow-pxp.jpg"
                alt="PXP flow diagram"
              />
            </Box>

            <Heading as="h2" variant="bigTitleXS">
              Goals
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              We aimed to address specific UX challenges, focusing on enhancing
              usability, performance, and emotional engagement across the
              platform.
              <ul>
                <li>
                  reduce or eliminate animations on components where usability
                  is compromised by excessive motion
                </li>
                <li>
                  Surface relevant content to website visitors + update content
                  to provide richer context and link imagery with more
                  emotionally resonant copy
                </li>
                <li>
                  Improve page exploration, discoverability and better connect
                  to other areas of the website
                </li>
                <li>
                  improve performance and user focus by expanding the use of
                  static components
                </li>
                <li>
                  refine scroll mechanics and timing adjustments to enhance
                  performance and user experience on both desktop and mobile
                </li>
                <li>
                  ensure video animations behave consistently across the site,
                  displaying text concurrently with video playback to optimize
                  page load times. Eliminate scroll-triggered and parallax
                  effects.
                </li>
                <li>
                  reduce unwanted negative space to improve content density and
                  user engagement, without disrupting the overall UI in special
                  use cases.
                </li>
              </ul>
            </Text>
            {/* IA PXP */}
            <Heading as="h3" variant="catHeading">
              Highlight
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Information Architecture - PXP
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              Sound Occasion approach to a formula proved to be succesful thus
              we approached the same solution for our PXP pages.{" "}
            </Text>
            <Box width={[1 / 1]} mb={[3, 4, 5]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-ia-pxp.jpg"
                alt="Information Architecture PXP"
              />
            </Box>
            <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-ia-to-wireframe-pxp.jpg"
                alt="Information Architecture PXP"
              />
            </Box>

            {/* Local navigation */}
            <Heading as="h2" variant="bigTitleXS">
              Local navigation
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              <strong>Valuable orientation and wayfinding aid</strong> &mdash;
              the local navigation indicates to users where they are and what
              other content is nearby in an information hierarchy.
            </Text>

            <Heading as="h3" variant="catHeading">
              Hypothesis
            </Heading>
            <Flex flexWrap="wrap" mb={[3]}>
              <Box width={[1 / 1, 1 / 1, 1 / 2]}>
                <Text as="p" pr={[0, 2, 4]} mb={[3, 4]}>
                  <strong>Problem statement</strong> <br />
                  The inability of users to easily access and understand product
                  overviews and distinctions may result in lower engagement and
                  reduced purchasing intent.
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 2]} mb={[3, 4]}>
                <Text as="p" pl={[0, 2, 4]} mb={[3, 4]}>
                  <strong>Solution</strong> <br />
                  Provide straightforward access to product categories and clear
                  explanations of their relevance and differences to enhance
                  user understanding and engagement.
                </Text>
              </Box>
            </Flex>

            <Box width={[1 / 1]} mb={[3, 4, 5]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-local-navigation-flow-pxp.jpg"
                alt="Local navigation structure"
              />
            </Box>
            <Box width={[1 / 1]} mb={[3, 4, 5]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-local-navigation-proposals-pxp.jpg"
                alt="Local navigation proposals"
              />
            </Box>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-local-navigation-pxp.jpg"
                alt="Local navigation proposals"
              />
            </Box>
            <Text as="span" variant="caption" mb={[5, 6]}>
              Mobile-first proposals prepared for user testing.
            </Text>

            {/* Communicating benefits */}
            <Heading as="h3" variant="catHeading">
              Highlight
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Communicating benefits by adding depth
            </Heading>
            <Text as="p" mb={[3, 4, 5]}>
              Beyond the page structure we introduced an approach that would
              facilitate the communication of our product’s benefits. This
              method showcases layered information, allowing users to explore
              topics of interest in greater depth at their own pace.
            </Text>
            <Text as="p" variant="paragraphXL" mb={[1, 2, 3]}>
              <strong>An invitation to discover</strong>
            </Text>
            <Flex flexWrap="wrap" mb={[3]}>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  Show & tell
                </Heading>
                <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                  <strong>Excite &#8594; Present</strong> <br />
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]} mb={[2, 3, 4]}>
                <Heading as="h3" variant="catHeading">
                  Learn
                </Heading>
                <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                  <strong>Educate &#8594; Expand</strong> <br />
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  Build trust
                </Heading>
                <Text as="p">
                  <strong>Convince &#8594; Emphasize</strong> <br />
                </Text>
              </Box>
            </Flex>
            <Box width={[1 / 1]} variant="wrapperImg">
              <StaticImage
                src="../../img/bose/b-pxp-present-educate-convince.jpg"
                alt="Wireframe with annotations for Excite / Educate / Convince"
              />
            </Box>
            <Text as="p" variant="caption" mb={[3, 4, 5]}>
              Wireframes were annotated to support the 'Excite, Educate,
              Convince' strategy, providing clear guidance on each step of the
              approach.
            </Text>
          </Box>

          {/* POV PXP */}
          <Box width={[1 / 1]}>
            <Box mx="auto" variant="containerTxt">
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Proof of concept
              </Heading>
              <Text as="p">
                The prototype highlights the present/expand/emphasize approach.
                Our objective was to gather directional feedback from users,
                which will guide us in making further incremental changes.
              </Text>
            </Box>
            <Flex flexWrap="wrap" justifyContent="center" variant="container">
              <Box width={[1 / 1, 4 / 12]} maxWidth="390px" mx={[0, 4]}>
                <Box variant="wrapperVideoPortrait">
                  <video autoPlay muted loop playsInline>
                    <source src={PXPVideoIntro} type="video/mp4" />
                  </video>
                </Box>
                <Text as="p" variant="caption">
                  The prototype is a work-in-progress, with some content still
                  pending, yet it has proven successful as a POV (point of
                  validation) with stakeholders.
                </Text>
              </Box>
              <Box width={[1 / 1, 8 / 12]}>
                <Box width={[1 / 1]} variant="wrapperVideo">
                  <video autoPlay muted loop playsInline>
                    <source src={PXPVideoTheme} type="video/mp4" />
                  </video>
                </Box>
              </Box>
            </Flex>
          </Box>

          {/* Deliverables */}
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              The End
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Deliverables
            </Heading>
            <ul>
              <li>Design System Guidelines & Recommedations</li>
              <li>UX Strategy & Approach</li>
              <li>Information Architecture</li>
              <li>Documentation</li>
              <li>
                Prototyping &mdash; Webflow &nbsp;
                <Link
                  variant="aLink"
                  href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
                  target="_blank"
                  rel="noopener norefferer"
                  mb={"0px"}
                >
                  Explore 'At Home'
                </Link>
                &nbsp; | &nbsp;
                <Link
                  variant="aLink"
                  href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/pxp/ultra-open-earbuds"
                  target="_blank"
                  rel="noopener norefferer"
                  mb={"0px"}
                >
                  PXP 'Ultra Open Earbuds'
                </Link>
              </li>
            </ul>
            <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
              Personal Note
            </Heading>
            <Text as="p">
              In large, global companies, it's crucial to recognize that change
              occurs incrementally and can be challenging to implement.
              Celebrating small victories is essential to maintain momentum and
              encourage ongoing progress.
            </Text>
            <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
              Out there
            </Heading>

            <Link
              variant="buttons.primary"
              href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
              target="_blank"
              rel="noopener norefferer"
              py={[3]}
              my={[3, 4]}
            >
              View prototype
            </Link>
          </Box>
        </Flex>
      </div>
      <ProjectTitleListing />
      <BackToTop />
    </section>
  );
}

export default ProjectBose;
